.table {
  display: table;
  /*   width: 100%;
  table-layout: fixed;
  min-width: 69rem; */
}

.row {
  display: table-row;
}

.alternaterow {
  background: #f7f7f7;
}

.cell {
  vertical-align: top;
  display: table-cell;
  padding: 0.25rem 0rem 0.25rem 0.25rem;
  border-bottom: 1px solid #cacaca;
}

.cell :global(.ant-row.ant-form-item) {
  padding-bottom: 0px !important;
}

.cell_name {
  width: 30%;
}

.cell_criteria {
  width: 25%;
  max-width: 15rem;
}

.cell_criteria > div {
  min-width: 6.5rem;
}

.cell_risk {
  vertical-align: top;
}

.cell_risk > div {
  min-width: 6.5rem;
}

.cell_riskamount > div {
  min-width: 9.1rem;
}

.cell_measurementAmount > div {
  min-width: 9.1rem;
}

.cell_comment {
  width: 70%;
}

.headercell {
  font-weight: bold;
  font-size: 12px;
}

.riskselector {
  display: flex;
}

.riskselector_indicator {
  margin-left: 0.25rem;
  width: 1rem;
  transition: background 0.25s ease-in-out;
}
