.wrapper {
  width: 100%;
  display: flex;
  min-width: 0;
  min-height: 0;
}

.text {
  flex: 1;
  min-width: 0;
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
}

.icon {
  display: inline-block;
  padding-left: 0.25rem;
}

.contentwrapper {
  max-width: 25rem;
  max-height: 20rem;
  overflow: auto;
}

.content {
  white-space: pre-line;
  word-break: break-word;
}

.title {
  text-transform: uppercase;
  color: #62a8ee;
  display: block;
}

.hint {
  color: #4d535f;
  display: block;
  font-size: 0.8rem;
}
