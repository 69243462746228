.actions {
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  min-width: 0;
  min-height: 0;
}

.action {
  display: flex;
  align-items: center;
  max-width: 25rem;
  margin: 0.5rem;
  text-align: left;
  overflow: hidden;
}

.action > span:not([role]) {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
