.table {
  display: table;
  width: 100%;
  /* table-layout: fixed; */
  min-width: 69rem;
}

.indicatorGrid {
  display: grid;
  grid-template-columns: 30% 20% 30% 20%;
  padding: 0 15px;
  width: 300px;
}

.row {
  display: table-row;
}

.alternaterow {
  display: table-row;
  background: #f9f9f9;
}

.cell {
  display: table-cell;
  padding: 0.25rem 0.25rem 0.25rem 0;
}

.cell_spacer {
  width: 1rem;
}

.panelLink {
  color: #62a8ee;
}

.cell_customerName {
  word-break: break-all;
}

.cell_offerNr {
  word-break: break-all;
}

.cell_psp {
  word-break: break-all;
}

.cell_risk {
  word-break: break-all;
}

.cell_machineType {
  word-break: break-all;
}

.headercell {
  font-weight: bold;
  font-size: 12px;
}

.cellcontent {
  line-height: 2rem;
}

.notfinished {
  background: #b6e4fe;
}

.finished {
  background: #cde1b2;
}

.notassigend {
  background: #e7e8ea;
}

.norisk {
  background: #3adf00;
}

.mediumrisk {
  background: #ffff00;
}

.highrisk {
  background: #df0101;
}

.riskselector {
  display: flex;
}

.riskselector_indicator {
  height: 0.8rem;
  width: 0.8rem;
  display: inline-block;
  border-radius: 20px;
  margin-top: 5px;
}

.statusRow {
  display: table-row;
}

.statusRow div[class^='StatusMonitorList_cell'] {
  display: inline-block;
}

.expandableRow > td {
  background-color: #e7e8ea;
  padding: 5px 5px 5px 25px;
}

.expandableRow .riskselector_indicator {
  height: 1rem;
  width: 5rem;
}

.statusHeader {
  padding: 5px 0;
}

.expandableRowStatusContent {
  padding-left: 10px;
}

@media (min-width: 1600px) {
  .expandableRow {
    display: none;
  }
}
