.mainwrapper {
  display: flex;
  flex: 1;
  min-height: 0;
  min-width: 0;
  flex-flow: column nowrap;
  padding: 0 0.5rem;
}

.maincontentwrapper,
.formwrapper {
  display: flex;
  flex: 1;
  min-height: 0;
  min-width: 0;
  overflow: hidden;
  flex-flow: column nowrap;
}

.mainnav {
  display: flex;
  justify-content: space-around;
  padding: 0.5rem 0;
}
