.sider {
  background: #fff;
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
}

.content {
  display: flex;
  flex: 1;
  min-height: 0;
  min-width: 0;
  overflow: hidden;
}

@media screen and (max-width: 1000px) {
  .siderMobile {
    position: absolute;
    z-index: 1000;
    top: 0;
    bottom: 0;
    height: 100%;
  }
}
