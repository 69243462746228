.risknotset {
  background: #b6e4fe;
}

.norisk {
  background: #3adf00;
}

.mediumrisk {
  background: #ffff00;
}

.highrisk {
  background: #df0101;
}
