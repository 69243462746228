.commandbarwrapper {
  display: flex;
  min-width: 0;
  padding: 0.25rem;
  padding-top: 0;
  background: white;
  border: 1px solid #7ab3cc;
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.38);
}

.actionwrapper {
  display: flex;
  flex: 1;
  min-width: 0;
  min-height: 0;
  justify-content: flex-end;
  align-items: center;
}

.actionwrapper > * {
  margin-left: 0.25rem !important;
  margin-top: 0.25rem !important;
}

.headerwrapper {
  display: flex;
  min-width: 0;
  min-height: 0;
  padding-left: 0.25rem;
  align-items: center;
}

.header_label {
  font-size: 1.3rem;
  text-transform: uppercase;
  color: #62a8ee;
  font-weight: 400;
}

.actionColumn {
  text-align: center;
  font-size: 1rem;
  margin: auto;
}

.breadcrumb {
  font-size: '1rem';
  font-weight: 300;
  text-transform: 'uppercase';
  margin: 'auto';
  color: '#202226';
}

.commandbarwrapper :global(.ant-breadcrumb > span:last-child) {
  color: #62a8ee;
  font-weight: 500;
}
