.editcontainer {
  display: flex;
  flex: 1;
}

.defaultscontainer {
  display: flex;
  flex: 1;
  margin-left: 0.5rem;
}

.container {
  display: flex;
  flex: 1 1 auto;
  flex-flow: row;
}

.table {
  display: table;
}

.row {
  display: table-row;
}

.namecell {
  display: table-cell;
  font-weight: bold;
}

.valuecell {
  display: table-cell;
}
