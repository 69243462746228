.contentWrapper {
  position: relative;
  flex: 1 1 auto;
  padding: 0.5rem 0rem;
  overflow: auto;
  min-height: 0;
  min-width: 0;
  height: 100%;
}

.flexContentWrapper {
  display: flex;
  flex-flow: column;
}

.card {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.38);
  padding: 0.5rem;
  border: 1px solid #7ab3cc;
  margin: 0 0.25rem 0.5rem 0;
  background: white;
  width: 100%;
  min-width: 0;
  min-height: 0;
}

.selectable:hover {
  border: 1px solid #62a8ee;
  box-shadow: 0px 10px 11px rgba(0, 0, 0, 0.22), -1px 4px 5px rgba(0, 0, 0, 0.18);
  transition: all 0.25s;
}

.isselected {
  border: 1px solid #62a8ee;
  box-shadow: 0px 10px 11px rgba(0, 0, 0, 0.22), -1px 4px 5px rgba(0, 0, 0, 0.18);
}

.toolBar {
  background-color: #fafafa;
  height: 40px;
  padding-top: 10px;
}

.toolBarSpan {
  vertical-align: middle;
  cursor: pointer;
  margin-left: 10px;
}

.toolBarIcon {
  font-size: 20px;
}

.divider {
  color: #5a5a5a !important;
  text-transform: uppercase !important;
  margin: 0.25rem 0 !important;
}

.shadow {
  -webkit-box-shadow: 1px 1px 2px -1px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 1px 1px 2px -1px rgba(0, 0, 0, 0.6);
  box-shadow: 1px 1px 2px -1px rgba(0, 0, 0, 0.6);
}
