.ant-menu-item-selected > a,
.ant-menu-item-selected > a:hover {
  color: #ffffff;
}
.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  color: white;
}

.ant-menu-inline-collapsed > .ant-menu-item .anticon + a {
  width: 0px;
  display: none !important;
}

.ant-menu-item {
  /*background: white !important;*/
}

.ant-layout {
  background: #e7e8ea;
  min-width: 0;
}

.ant-form-item {
  margin-bottom: 0;
}

.ant-select {
  width: 100%;
}

.ant-drawer-body :global {
  padding: 10px !important;
}

.ant-input-number-input {
  text-align: right;
  padding-right: 25px;
}

.ant-form-vertical .ant-form-item-label {
  padding: 0;
}

.ant-statistic-content-value-decimal {
  font-size: 12px;
}

.ant-statistic-content-value-int {
  font-size: 15px;
}

textarea.ant-input {
  margin-bottom: 0px !important;
}

.ant-divider-horizontal.ant-divider-with-text-left::before {
  /*   top: -3px!important;
  display: inline-block!important;
  width: 50px!important; */
}

.customIcon {
  width: 12px;
  position: relative;
}

/* .statusIcon {
  width: 15px;
} */

.statusIcon .customIcon path {
  fill: #4d535f !important;
}

.customIcon path {
  fill: #fff;
}

.statusIcon {
  position: relative;
  top: 2px;
}

.customIcon rect {
  background: none;
  fill: none;
  stroke: #fff;
  stroke-width: 0.5px;
}

span .customIcon path {
  fill: #000000;
}

span .customIcon rect {
  background: none;
  fill: none;
  stroke: #000000;
  stroke-width: 0.5px;
}

.archiveIcon {
  width: 11px;
  top: 2px;
}

/* div[class^="SortableCard_divider"] {


}  */

.ant-divider-horizontal.ant-divider-with-text-left::before,
.ant-divider-horizontal.ant-divider-with-text-left::after {
  top: -5px !important;
  display: inline-block !important;
}

.ant-divider-horizontal.ant-divider-with-text-left {
  overflow: hidden;
  text-overflow: ellipsis;
  height: 100%;
  width: 100% !important;
  display: block !important;
  white-space: nowrap;
  text-align: center;
  padding-right: 15px;
}

.ant-divider-inner-text {
  text-align: left;
}

/*Responsiveness handling of ant tables under RiskAssessmentLists*/

.ant-table-tbody > tr > td {
  word-break: break-word;
}

@media (max-width: 1200px) {
  div[class^='RiskAssessmentList_list'] .ant-table-header .ant-table-thead > tr > th:nth-last-child(2) {
    display: none;
  }
  div[class^='RiskAssessmentList_list'] .ant-table-body .ant-table-tbody > tr > td:nth-last-child(2) {
    display: none;
  }
  div[class^='RiskAssessmentList_list'] .ant-table-header .ant-table-thead > tr > th:last-child::after {
    content: '...';
  }
}

@media (max-width: 1150px) {
  div[class^='RiskAssessmentList_list'] .ant-table-header .ant-table-thead > tr > th:nth-last-child(3) {
    display: none;
  }
  div[class^='RiskAssessmentList_list'] .ant-table-body .ant-table-tbody > tr > td:nth-last-child(3) {
    display: none;
  }
}

@media (max-width: 1050px) {
  div[class^='RiskAssessmentList_list'] .ant-table-header .ant-table-thead > tr > th:nth-last-child(4) {
    display: none;
  }
  div[class^='RiskAssessmentList_list'] .ant-table-body .ant-table-tbody > tr > td:nth-last-child(4) {
    display: none;
  }
}

@media (max-width: 950px) {
  div[class^='RiskAssessmentList_list'] .ant-table-header .ant-table-thead > tr > th:nth-last-child(5) {
    display: none;
  }
  div[class^='RiskAssessmentList_list'] .ant-table-body .ant-table-tbody > tr > td:nth-last-child(5) {
    display: none;
  }
}

@media (max-width: 850px) {
  div[class^='RiskAssessmentList_list'] .ant-table-header .ant-table-thead > tr > th:nth-last-child(6) {
    display: none;
  }
  div[class^='RiskAssessmentList_list'] .ant-table-body .ant-table-tbody > tr > td:nth-last-child(6) {
    display: none;
  }
}

.ant-table-tbody > tr td,
.ant-table-thead > tr th {
  min-width: 100px !important;
  width: 100px !important;
}

/* .ant-table-tbody>tr td:first-child div, .ant-table-thead>tr th:first-child div {
  width: 50px!important;
  min-width: 50px!important;
} */

.ant-col-1 {
  width: unset !important;
}

.infoRiskButton {
  margin: 15px 5px;
}

.ant-form-item-control {
  line-height: inherit;
}

.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  background: #62a8ee;
}
