.rowCard {
  display: flex;
  flex: 1 1 auto;
  position: relative;
  overflow: auto;
  margin: 0.25rem;
  padding: 0.25rem;
  border: 1px solid #e8e8e8;
}

.actionContainer {
  text-align: right;
  position: absolute;
  right: 5px;
  top: 0px;
  height: 21px;
  line-height: 21px;
}

.arrowDelete {
  text-align: right;
  position: relative;
}

.rowCheckbox {
  margin-right: 0.5rem;
  display: flex;
  flex: 1 1 auto;
  position: relative;
  margin: 0.25rem;
  padding: 5px;
}

.rowContent {
  display: inline;
  width: 100%;
  padding-left: 20px;
  overflow: hidden;
}

.rowContainer {
  padding: 5px !important;
}

.List {
  width: 40%;
  height: 450px;
  overflow-y: auto;
  border: 1px solid #e8e8e8;
}

.Form {
  width: 80%;
  height: 450px;
  overflow-y: auto;
}

.Container {
  width: 100%;
  display: flex;
  flex: 1 1 auto;
  position: relative;
  overflow: auto;
}

.actionButton {
  display: flex;
  flex: 1 1 auto;
  position: relative;
  overflow: auto;
  width: 20%;
}

.noMarginBottom {
  margin-bottom: 0px !important;
}
