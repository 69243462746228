.content {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.contentwrapper {
  padding: 0 0.5rem;
  display: flex;
  flex-flow: column;
  flex: 1;
}

.card {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
  border-color: rgba(0, 0, 0, 0.09);
  margin: 0.5rem 0;
}

.permissionheader {
  font-size: 0.9rem;
  text-transform: uppercase;
}

.permissionscontainer {
  display: flex;
  flex-flow: column nowrap;
}

.permissionslider_container {
  display: flex;
  padding: 0.25rem;
}

.permissionslider_label {
  flex: 1;
  margin-left: 0.5rem;
}

.alertStyle {
  margin: 0.25rem;
}

.tenantcard {
  width: 140px;
  margin: 5px;
}

.selectedtenantcard {
  border-color: #62a8ee;
}

.tenantcardimage {
  max-width: 80%;
  height: 30px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

.tenantcardtitle {
  text-transform: uppercase;
}

.tenantcardcontainer {
  display: flex;
  margin-bottom: 1rem;
}

.drawerContent {
  display: flex;
  flex-flow: column;
}

.firstLine {
  font-weight: 100;
}
.secondLine {
  font-size: 12px;
}
.thirdLine {
  font-size: 11px;
}
