.header {
  display: flex;
  align-items: center;
  height: 3.5rem;
  padding: 0.25rem 0 0.25rem 0rem;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  line-height: inherit;
  z-index: 1000;
}

.menuTrigger {
  display: inline-block;
  margin-left: 1.5rem;
  margin-top: 0.25rem;
  font-size: 18px;
  color: #d8dcdf;
}
.headerActions {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding-right: 1rem;
  height: 100%;
  align-items: center;
}

.action {
  cursor: pointer;
  padding: 0 12px;
  display: inline-block;
  transition: all 0.3s;
  height: 100%;
}

.action > i {
  vertical-align: middle;
  color: 'black';
}

.account {
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin-left: 1rem;
  margin-right: 1rem;
  min-width: 5rem;
}

.language {
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin-left: 1rem;
  margin-right: 1rem;
  min-width: 5rem;
}

.branchSelection {
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin-left: 1rem;
  margin-right: 1rem;
  min-width: 8rem;
}

.name {
  font-size: 9pt;
}

.tenant {
  font-size: 9pt;
  color: #7d7d7d;
}

.branch {
  font-size: 9pt;
  color: #7d7d7d;
}

.version {
  font-size: 9pt;
  color: #7d7d7d;
}

.subMenuActive {
  color: white;
}

@media screen and (max-width: 1000px), screen and (max-height: 700px) {
  .name {
    font-size: 8pt;
  }

  .tenant {
    font-size: 7pt;
  }

  .branch {
    font-size: 7pt;
  }
}
