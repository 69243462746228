.actionContainer {
  text-align: right;
  position: absolute;
  line-height: 21px;
  height: 21;
}

.arrowUp {
  text-align: right;
  position: relative;
}

.arrowDown {
  text-align: right;
  position: relative;
}

.arrowDelete {
  text-align: right;
  position: relative;
}

.card {
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  position: relative;
  overflow: auto;
  padding: 0.25rem;
  border: 1px solid #e8e8e8;
  margin: 0 0.25rem 0.25rem 0;
  background: white;
  -webkit-box-shadow: 1px 1px 2px -1px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 1px 1px 2px -1px rgba(0, 0, 0, 0.6);
  box-shadow: 1px 1px 2px -1px rgba(0, 0, 0, 0.6);
}

.divider {
  color: #5a5a5a !important;
  text-transform: uppercase !important;
  margin: 12px 0 0 0 !important;
}

.contentContainer {
  display: flex;
  flex: 1 1 auto;
}

.sortActions {
  width: 25px;
  border-right: 1px solid lightgray;
  margin-right: 10px;
}

.cardContent {
  width: 100%;
  overflow: hidden;
}
