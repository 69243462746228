.commandbarwrapper {
  display: flex;
  min-width: 0;
  /* min-height: 40px; */
}

.actionwrapper {
  display: flex;
  flex: 1;
  min-width: 0;
  min-height: 0;
  justify-content: flex-end;
  padding-top: 0.25rem;
}

.actionwrapper > button {
  margin-left: 0.25rem;
  margin-top: 0.25rem;
}

.headerwrapper {
  display: flex;
  min-width: 0;
  min-height: 0;
  padding-left: 0.25rem;
  padding-top: 0.5rem;
}

.header_label {
  font-size: 1.3rem;
  text-transform: uppercase;
  color: #62a8ee;
  font-weight: 400;
}

.actionColumn {
  text-align: center;
  font-size: 1rem;
  margin: auto;
}

.infoColumnLabel {
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
  margin: 0 10px;
}

.infoColumnContent {
  font-weight: 500;
  margin: 0 10px;
}

.infoColumnContainer {
  display: inline;
}

.project_type_label {
  text-align: center;
  margin-right: 5px;
}

.breadcrumb {
  font-size: '1rem';
  font-weight: 300;
  text-transform: 'uppercase';
  margin: 'auto';
  color: '#202226';
}
