.list {
  overflow-y: auto;
  overflow-x: hidden;
}

.uploadButton {
  margin: 0.5rem;
  max-width: 200px;
}

.actionButton {
  margin-left: 5px;
}

.showArchiveIcon {
  float: right;
}

.statusIcon {
  fill: #4d535f !important;
}

.statusColHeader {
  width: 50px !important;
  display: inline-block;
}

.documentLink {
  margin-right: 10px;
}

.statusCol {
  width: 20px !important;
  display: inline-block;
}

:global(.ant-table-tbody) > tr td.iconcolumn,
:global(.ant-table-thead) > tr th.iconcolumn {
  width: 25px !important;
  min-width: 25px !important;
}

:global(.ant-table-tbody) > tr td.versioncolumn,
:global(.ant-table-thead) > tr th.versioncolumn {
  width: 70px !important;
  min-width: 70px !important;
}

.actionContainer {
  display: flex;
}

.statusIconsContainer {
  display: flex;
}

.statusIconsContainer > *:not(:first-child) {
  margin-left: 0.25rem;
}
