.commenthistory {
  position: relative;
  height: 100%;
}

.actioncontainer {
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 1;
}

.commentlist {
  overflow: auto;
  padding-top: 0.5rem;
}

.commentlist :global .ant-comment-inner {
  padding: 0;
}

.nocomments {
  line-height: 2rem;
  padding-left: 12px;
}
