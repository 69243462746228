.mainnav_link {
  font-size: 16px;
  text-transform: uppercase;
  border-bottom: 2px solid transparent;
}

.mainnav_link_active {
  font-size: 16px;
  text-transform: uppercase;
  border-bottom: 2px solid #62a8ee;
  transition: all 0.5s ease-out;
}

.mainnav_link_span {
  line-height: 2rem;
}

.mainnav_link_icon {
  margin-right: 0.25rem;
}
