.table {
  width: 100%;
}

.row {
}

.alternaterow {
  background: #f7f7f7;
}

.totalrow {
  background: #e4e4e4;
  font-weight: 500;
}

.headericon {
  margin-right: 0.5rem;
}

.departmentcell {
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
  text-transform: uppercase;
  border-right: 5px double #8c8c8c;
  border-bottom: 2px solid #8c8c8c;
  padding-right: 4px;
}

.summarycell {
  font-weight: 500;
  font-size: 1rem;
  text-transform: uppercase;
  text-align: center;
  border-bottom: 2px solid #8c8c8c;
}

.cell {
  padding: 0.25rem 0rem 0.25rem 0.25rem;
  vertical-align: top;
  border-bottom: 1px solid #cacaca;
}

.cell :global(.ant-row.ant-form-item) {
  padding-bottom: 0px !important;
}

.cell_departmentName {
  width: 10rem;
  font-weight: bold;
}

.cell_status {
  width: 1.3rem;
}

.cell_evaluationStatusMessage {
  width: 13rem;
}

.cell_infos {
  min-width: 19rem;
}

.cell_evaluationComment {
  max-width: 13rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.cell_risk {
  width: 1.25rem;
}

.cell_riskamount {
  min-width: 9.1rem;
  text-align: right;
  border-right: 2px solid #ababab;
  padding-right: 4px;
}

.cell_measurementAmount {
  min-width: 9.1rem;
  text-align: right;
  border-right: 2px solid #ababab;
  padding-right: 4px;
}

.cell_riskAfterMeasurement {
  min-width: 9.1rem;
  text-align: right;
  border-right: 5px double #8c8c8c;
  padding-right: 4px;
}

.cell_summaryRiskAfterMeasureAmount {
  min-width: 9.1rem;
  border-right: 2px solid #ababab;
  padding-right: 4px;
  text-align: right;
}

.cell_summaryMeasureAmount {
  min-width: 9.1rem;
  border-right: 2px solid #ababab;
  padding-right: 4px;
  text-align: right;
}

.cell_summaryComment {
  width: 30%;
  min-width: 20rem;
}

.cell_totalwithcurrency {
  padding-right: 0.7rem;
  text-align: right;
}

.headercell {
  font-weight: bold;
  font-size: 12px;
  text-align: center;
}

.cellcontent {
}

.riskselector {
  display: flex;
}

.riskselector_indicator {
  width: 1rem;
  height: 1rem;
}

.infostable {
  width: 100%;
  table-layout: fixed;
  font-size: 0.8rem;
}

.info_description_cell {
  font-weight: 500;
  width: 6rem;
}
