.evaluationdatacontainer {
  display: flex;
  min-height: 0;
  min-width: 0;
}

@media (max-width: 1500px) {
  .evaluationdatacontainer {
    flex-direction: row !important;
    flex-wrap: wrap !important;
  }
}

.evaluationcontainer {
  display: flex;
  /* flex: 1; */
  min-height: 0;
  min-width: 0;
}

.reportAction {
  width: 100%;
  text-align: right;
}

.calculationbutton {
  margin-top: 22px;
}

.calculationbuttoncontainer {
  display: flex;
}

.riskvalue {
  margin-right: 25px;
}

.textcontainer {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
}
