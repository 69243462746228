/* .nav_link {
  text-transform: uppercase;
  border-bottom: 2px solid transparent;
  padding-left: 0.25rem;
}

.nav_link_active {
  padding-left: 0.25rem;
  text-transform: uppercase;
  border-bottom: 2px solid #62a8ee;
  transition: all 0.5s ease-out;
}

.nav_link_span {
  line-height: 2rem;
  font-weight: bold;
}
*/

.nav_link_icon {
  margin-right: 0.25rem !important;
}
