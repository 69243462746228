.addArea {
  position: relative;
  width: 50%;
  height: 30px;
  text-align: center;
  background: #fdfdfd;
  border: 1px dashed #d9d9d9;
  border-radius: 0;
  cursor: pointer;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
  margin: 0.5rem;
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
}

.cardSelect {
  margin-left: 20px;
  width: 100%;
}

.noMarginBottom {
  margin-bottom: 0px !important;
}

.dividerStyle {
  color: rgba(0, 0, 0, 0.85) !important;
  text-transform: inherit !important;
  font-size: 0.9rem !important;
  margin-bottom: 0px;
}

.addcontainer {
  display: flex;
  max-width: 20rem;
}
