.actions {
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  min-width: 0;
  min-height: 0;
}

.action {
  max-width: 20rem;
  margin: 0.5rem;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header {
  display: flex;
  line-height: 1rem;
}

.active {
  background: #dbedff;
}

button.action > span {
  display: inline;
}
