.table {
  display: table;
  table-layout: fixed;
}

.row {
  display: table-row;
  line-height: 2.5rem;
}

.control_cell {
  display: table-cell;
}

.message_cell {
  display: table-cell;
  padding-left: 0.5rem;
  padding-bottom: 0.25rem;
}

.listitem {
  list-style-type: none;
}

.listitem_icon {
  margin-right: 0.5rem;
  color: #e29aa2;
}

.cclist {
  margin-top: 1rem;
}

.backContainer {
  margin-top: 1rem;
}
