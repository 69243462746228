.margin {
  margin: 0.25rem 0;
}

.contentwrapper {
  padding: 0 0.5rem;
  display: flex;
  flex-flow: column;
  flex: 1;
}

.list {
  flex: 1;
  min-height: 0;
  min-width: 0;
}

.panelLink {
  color: #62a8ee;
}
