.header {
  display: flex;
}

.column {
  display: flex;
  padding: 0.25rem;
  flex: 1;
}

.table {
  display: table;
}

.statuscontainer {
  display: flex;
  flex-flow: column nowrap;
  margin-left: 0.5rem;
}

.infocontainer {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  border: 1px solid #e6e6e6;
  background: white;
  box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.38);
}

.verticalcentered {
  display: flex;
  flex-flow: column nowrap;
  border: 1px solid #e6e6e6;
  padding: 0.25rem;
  background: white;
  box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.38);
}

.status {
  text-align: center;
  font-size: 0.9rem;
  text-transform: uppercase;
  color: #62a8ee;
}

.status_label {
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
  color: #666666;
}

.project_type_label {
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
  color: #666666;
}

.info {
  display: row;
}
