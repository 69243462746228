.header {
  font-weight: bold;
}

.drawerContainer {
  width: 100%;
  height: 100%;
  display: flex;
}

.riskstable {
  table-layout: fixed;
}

.topicstable {
  table-layout: fixed;
}

.scrollContainer {
  overflow: auto;
  width: 100%;
  padding-top: 0.5rem;
}

.alignRight {
  text-align: right;
}

.tablecol {
  padding-right: 0.5rem;
}

.alternaterow {
  background: #f7f7f7;
}

.titlecell {
  font-weight: 300;
  color: #4d535f;
}
