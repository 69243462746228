.headerDropdown.container > * {
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

@media screen and (max-width: 480px) {
  .container {
    width: 100% !important;
  }
  .container > * {
    border-radius: 0 !important;
  }
}
