.maincontainer {
  min-height: 0;
  min-width: 0;
  overflow-y: auto;
  padding-top: 0.5rem;
}

@media (max-width: 776px) {
  .maincontainer {
    /* flex-direction: row!important;
    flex-wrap: wrap!important; */
  }
}

.evaluationsummarycontainer {
  min-height: 0;
  min-width: 0;
}

.infocontainer {
  display: flex;
  min-height: 0;
  min-width: 0;
}

.infoitem {
  display: flex;
  flex: 1;
}

.table {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.row {
  display: table-row;
}

.label {
  display: table-cell;
  font-weight: bold;
}

.content {
  display: table-cell;
  padding: 0 1rem;
}

.reportAction {
  width: 100%;
  text-align: right;
}
