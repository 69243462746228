.ant-table-tbody > tr td:nth-of-type(1) {
  min-width: 150px;
  width: 150px;
}

.ant-table-tbody > tr td:nth-of-type(2) {
  min-width: 100px;
  width: 100px;
}

.ant-table-tbody > tr td:nth-of-type(3) {
  min-width: 100px;
  width: 100px;
}

.ant-table-tbody > tr td:nth-of-type(4) {
  min-width: 150px;
  width: 150px;
}

.ant-table-tbody > tr td:nth-of-type(5) {
  min-width: 100px;
  width: 100px;
}

.ant-table-tbody > tr td:nth-of-type(6) {
  min-width: 100px;
  width: 100px;
}

.ant-table-tbody > tr td:nth-of-type(7) {
  min-width: 100px;
  width: 100px;
}

.ant-table-tbody > tr td:nth-of-type(8) {
  min-width: 100px;
  width: 100px;
}

.ant-table-tbody > tr td:nth-of-type(9) {
  min-width: 60px;
  width: 60px;
}

.ant-table-thead > tr th:nth-of-type(1) {
  min-width: 150px;
  width: 150px;
}

.ant-table-thead > tr th:nth-of-type(2) {
  min-width: 100px;
  width: 100px;
}

.ant-table-thead > tr th:nth-of-type(3) {
  min-width: 100px;
  width: 100px;
}

.ant-table-thead > tr th:nth-of-type(4) {
  min-width: 150px;
  width: 150px;
}

.ant-table-thead > tr th:nth-of-type(5) {
  min-width: 100px;
  width: 100px;
}

.ant-table-thead > tr th:nth-of-type(6) {
  min-width: 100px;
  width: 100px;
}

.ant-table-thead > tr th:nth-of-type(7) {
  min-width: 100px;
  width: 100px;
}

.ant-table-thead > tr th:nth-of-type(8) {
  min-width: 100px;
  width: 100px;
}

.ant-table-thead > tr th:nth-of-type(9) {
  min-width: 60px;
  width: 60px;
}
