.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 0.5rem;
}

.title {
  flex: 1;
  margin-right: 8px;
  display: inline;
  font-size: 0.8rem;
}
