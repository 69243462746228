.card {
  width: 240px;
  margin: 5px;
}

.selectedCard {
  border-color: black;
}

.cardImage {
  max-width: 80%;
  height: 30px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

.flexContainer {
  padding: 0;
  margin: 0;
  display: flex;

  align-items: center;
  justify-content: center;
  flex-flow: column;
}

.flexRow {
  flex-flow: row;
  display: flex;
}

.headline {
  margin: 15px;
}
