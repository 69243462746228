.formcontainer {
  max-width: 20rem;
}

.total {
  color: #62a8ee;
}

.risknote {
  color: #3e3e3e;
}
