.table {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.row {
  display: table-row;
}

.cell {
  display: table-cell;
  padding: 0.25rem 0.25rem 0.25rem 0;
  vertical-align: middle;
}

.cell_departmentName {
  width: 10rem;
}

.cell_status {
  width: 1.3rem;
}

.cell_evaluationStatusMessage {
  width: 8rem;
}

.cell_evaluationComment {
  width: 8rem;
}

.cell_risk {
  width: 1.25rem;
}

.cell_riskamount {
  width: 6rem;
  text-align: right;
}

.cell_measurementAmount {
  width: 6rem;
  text-align: right;
}

.cell_riskAfterMeasurement {
  width: 6rem;
  text-align: right;
}

.cell_summaryRiskAfterMeasureAmount {
  width: 8rem;
}

.cell_summaryMeasureAmount {
  width: 8rem;
}

.cell_summaryComment {
  width: 100%;
}

.headercell {
  font-weight: bold;
  font-size: 12px;
}

.cellcontent {
  line-height: 2rem;
}

.riskselector {
  display: flex;
}

.riskselector_indicator {
  width: 1rem;
  height: 1rem;
}
