.maincontainer {
  /* display: flex;
  flex-flow: column nowrap;
  flex: 1;
  min-width: 0;
  min-height: 0; */
  padding-top: 0.5rem;
}

.splitcontainer {
  display: flex;
  flex: 1;
  min-width: 0;
  min-height: 0;
}

.historycontainer {
  display: flex;
  flex: 1;
  min-width: 0;
  min-height: 0;
}

.middlecontainer {
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  min-width: 0;
  min-height: 0;
  margin-left: 1rem;
}

.actionscontainer {
}

.evaluationoverviewcontainer {
  /*display: flex;*/
  min-width: 0;
  min-height: 0;
}

.historyentrywithdata {
  cursor: pointer;
}

.historyentrywithdata:hover {
  color: #62a8ee;
}

.historyentry_title {
  color: gray;
  font-size: 0.7rem;
}

.receiver_table {
  display: table;
  table-layout: fixed;
}

.receiver_row {
  display: table-row;
}

.receiver_cell {
  display: table-cell;
  padding-right: 0.5rem;
}
