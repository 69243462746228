.maincontentwrapper {
  min-height: 0;
  min-width: 0;
}

.action {
  max-width: 20rem;
  margin: 0.5rem;
  text-align: left;
}

.nav {
  display: flex;
  justify-content: space-around;
  font-size: 0.8rem;
  padding: 0rem 1.5rem 0.5rem 0.25rem;
}

.maincontainer {
  display: flex;
  flex: 2;
  min-height: 0;
  min-width: 0;
  padding-top: 0.5rem;
}

.reportAction {
  width: 100%;
  text-align: right;
}

.sidecontainer {
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  min-height: 0;
  min-width: 0;
}

.tabcontainer {
  flex: 1;
  min-width: 0;
  min-height: 0;
  overflow: hidden;
}

.actionbutton {
  margin-left: 0.25rem;
  margin-top: 0.25rem;
}

.infocontainer {
  display: flex;
  min-height: 0;
  min-width: 0;
}

@media screen and (max-width: 1200px) {
  .infocontainer {
    flex-flow: column;
  }
}

.documentscontainer {
  display: flex;
  min-height: 0;
  min-width: 0;
  height: 21rem;
}

.infoitem {
  display: flex;
  min-height: 0;
  min-width: 0;
  flex: 1;
}

.header {
  font-weight: bold;
}

.riskstable {
  table-layout: fixed;
}

.alignRight {
  text-align: right;
}

.tablecol {
  padding-right: 0.5rem;
}

.alternaterow {
  background: #f7f7f7;
}

.titlecell {
  font-weight: 300;
  color: #4d535f;
}

.historyactions {
  position: absolute;
  right: 0.25rem;
  top: 0.25rem;
}
