.mainwrapper {
  display: flex;
  flex: 1;
  min-height: 0;
  min-width: 0;
  flex-flow: column nowrap;
  padding: 0 0.5rem;
  overflow: hidden;
}

.headerwrapper {
}

.maincontentwrapper {
  display: flex;
  flex: 1;
  min-height: 0;
  min-width: 0;
  overflow: hidden;
  flex-flow: column nowrap;
}

.maincontent {
  display: flex;
  flex: 1;
  flex-flow: column;
  min-height: 0;
  min-width: 0;
  margin-top: 1rem;
  overflow: auto;
}

.mainnav {
  padding-top: 0.5rem;
  box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.38);
  background: white;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0;
  flex: 0 0 auto;
}

.mainnav_link {
  font-size: 16px;
  text-transform: uppercase;
  border-bottom: 2px solid transparent;
}

.mainnav_link_active {
  font-size: 16px;
  text-transform: uppercase;
  border-bottom: 2px solid #62a8ee;
  transition: all 0.5s ease-out;
}

.mainnav_link_span {
  line-height: 2rem;
}

.mainnav_link_icon {
  margin-right: 0.25rem;
}

.scrollcontainer {
  flex: 1;
  overflow: auto;
}

.commandbarwrapper {
  min-width: 5rem;
}

.contentScrollWrapper {
  overflow: auto;
}

@media screen and (max-width: 1000px), screen and (max-height: 700px) {
  .contentScrollWrapper {
    overflow: scroll;
  }
}

.smallDeviceInfoContainer {
  display: flex;
  flex-flow: row wrap;
}

.infoItem_container {
  display: flex;
  flex-flow: column;
  margin-bottom: 0.5rem;
}

.infoItem_label {
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
  margin: 0 10px;
}

.infoItem_content {
  font-weight: 500;
  margin: 0 10px;
}
