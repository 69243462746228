.active {
  color: white !important;
  display: inline !important;
}

.menuentry_link {
  display: inline !important;
}

.menuentry_top_link {
  display: inline !important;
}

.sideNav {
  height: 100%;
  width: 265px;
}



.navMenu {
  padding: 16px 0;
  width: 100%;
}

.logoContainer {
  padding: 15px;
}

.logoContainer > img {
  display: block;
  margin: 0 auto;
  height: 30px;
}

.ant-layout-sider-collapsed .logoContainer {
  padding: 0.5rem 0.25rem;
}

/* .ant-layout-sider-collapsed .menuentry_link {
  display: none;
} */
