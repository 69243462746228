.container {
  margin-top: 0.5rem;
  display: flex;
}

.title {
  margin-right: 8px;
  display: inline;
  font-size: 0.8rem;
}

.tagcontainer {
  flex: 1;
}

.tag {
  margin-bottom: 8px;
  margin-right: 8px;
}
