.dividerStyle {
  color: rgba(0, 0, 0, 0.85) !important;
  text-transform: inherit !important;
  font-size: 0.9rem !important;
  margin-bottom: 0px;
}

.departmentSyncButtons {
  display: flex;
  justify-content: right;
}

.marginButton {
  margin-bottom: 10px;
}
