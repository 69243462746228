.table_row {
  display: table-row;
}

.table_column {
  display: table-cell;
}

.column_label {
  display: table-cell;
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
  color: #666666;
}

.column_content {
  display: table-cell;
  font-weight: 500;
  padding-left: 0.25rem;
}

.table_rowOuter > div > span:nth-of-type(2) {
  display: block;
  font-weight: 500;
  padding-left: 1rem;
  border-top: 1px solid #ccc;
  /* width: 100%; */
  padding-top: 10px;
}

.table_rowOuter > div {
  display: block;
}

.table_rowOuter > div > span:nth-of-type(1) {
  font-size: 14px;
  position: relative;
  background: #fff;
  padding: 0px 5px 5px 5px;
  display: inline-block;
  position: relative;
  top: 14px;
}
